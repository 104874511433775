<template>
  <div class="pl-3 mt-3">
    <PersonalizationHeader
      :title="$t('settingsPage.personalization')"
      :has-action="false"
      :filter-type="filterType"
      :update-tab="updateTab"
      :total-status-count="getTotalStatusCount"
      :total-priority-count="getTotalPriorityCount"
    />
    <Loader v-if="loaderState" />
    <template v-else>
      <v-card
        class="py-6 px-6 mt-3 text-left"
        rounded="lg"
        elevation="0"
        width="100%"
      >
        <v-row class="justify-space-between align-center pt-4 pb-4 px-3 py-3">
          <h2 class="text-theme-base">
            {{ filterType === 'statusColors' ? $t('Statuses') : $t('Priorities') }}
          </h2>
          <AddStatus 
            :personalization-type="filterType == 'statusColors' && 'Status' || 'Priority'"
            :on-add="onCreateOrUpdate"
            :current-user="currentUser"
            :show-dialog.sync="showDialog"
            :modal-open="modalOpen"
            :action="action"
            :selected-item="selectedItem"
            :total-count="getTotalCountByFilterType"
            :selected-counts="isCompletedCount"
            @update:colors="updateColors" 
          />
        </v-row>
        <div class="d-flex align-center justify-start ml-0 py-4">
          <div 
            v-for="entity in getEntityTypes" 
            :key="entity.value" 
            class="ml-2"
          >
            <v-chip
              :class="{ 'blue--text': subTab === entity.value }"
              width="115px"
              :color="subTab === entity.value ? '#e6ecff' : '#f9fafb'"
              label
              @click="updateSubTab(entity.value)"
            >
              <div :class="{'font-weight-bold': subTab === entity.value, 'px-2': true }">
                {{ $t(entity.name) }} 
                <span class="ml-2">{{ getCount(entity.value) }}</span>
              </div>
            </v-chip>
          </div>
        </div>
        <ColorsTable
          :headers="filteredHeaders"
          :on-edit="onCreateOrUpdate"
          :items="colorItems"
          item-key="id"
          class="mt-3"
          type="status"
          @change-color="changeColor"
        />
      </v-card>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';
import * as _ from 'lodash'
import makeUserService from '@/services/api/user';
import makeHandleService from '@/services/api/handle';
import ColorsTable from '@/components/Settings/DataColors/ColorsTable.vue';
import PersonalizationHeader from '@/components/Settings/Personalization/PersonalizationHeader.vue';
import AddStatus from '@/components/Settings/Personalization/AddStatus.vue';
import Loader from '@/components/base/Loader.vue';
import { sleep } from '@/utils/util';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { entityTypes } from '@/constants/colors.js';

const { mapState, mapGetters: mapUserGetters, mapActions: mapUserActions } = createNamespacedHelpers('user');

let userService;
let handleService;

export default {
  name: 'DataColors',

  components: {
    PersonalizationHeader,
    ColorsTable,
    AddStatus,
    Loader
  },

  data () {
    return {
      headers: [],
      currentUser: undefined,
      subTab: 'testRun',
      currentOrg: undefined,
      filterType: 'statusColors',
      showDialog: false,
			action: 'Add',
			selectedItem: {},
      loaderState: false
    }
  },

  computed: {
    ...mapState(["currentAccount"]),
    ...mapUserGetters({
      getUserPreferences: "getUserPreferences",
    }),
    ...mapGetters({ 
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    filteredHeaders() {
      const filtered = this.headers.filter((header) => !header.checked);
      return filtered;
    },
    isCompletedCount(){
      return this.colorItems.filter(item => item.isCompleted).length
    },
    getEntityTypes() {
      return entityTypes;
    },
    colorItems() {
      return this.colors[this.filterType]?.filter(color => color.entityType == this.subTab) || [];
    },
    preferencesState() {
      return this.getUserPreferences || {};
    },
    preferencesTimestamp() {
      return this.preferencesState?.timestamp || null;
    },
    getTotalStatusCount() {
      return this.colors["statusColors"]?.length || 0;
		},
    getTotalPriorityCount() {
      return this.colors["priorityColors"]?.length || 0;
    },
    getTotalCountByFilterType() {
      return this.colors[this.filterType]?.length || 0;
    },
    colors: {
      get() {
        return {
          statusColors: this.preferencesState?.statusColors || [],
          priorityColors: this.preferencesState?.priorityColors || [],
        }
      },
      set(value) {
        this.setUserPreferences(
          {
            ...value,
            timestamp: this.preferencesTimestamp
          }
        );
      }
    }
  },
  created() {
    userService = makeUserService(this.$api);
    handleService = makeHandleService(this.$api);
    if(!this.dynamicHeaders.personalizations) {
      this.initializeHeaders({ type: 'personalizations' });
    }
    this.headers = this.dynamicHeaders.personalizations;
  },
  methods: {
    ...mapActions({
      setLoading: 'setLoading',
    }),
    ...mapUserActions(['setUserPreferences']),
    ...mapActions("headers", ['initializeHeaders']),
    updateTab(type) {
      this.filterType = type;
    },
		onCreateOrUpdate(action, id, key) {
			this.showDialog = true;
			this.action = action;
			this.selectedItem = key && this.colors.default[this.filterType].find(color => color.id == id) || this.colors[this.filterType].find(color => color.id == id)
		},
		modalOpen(type) {
			this.showDialog = type;
      this.selectedItem = type ? this.selectedItem : [];
		},
		getCount(tab) {
      const customColorCount = this.colors[this.filterType]?.filter(color => color.entityType == tab)?.length || 0;
      return customColorCount;
		},
    updateSubTab(type) {
      this.subTab = type;
    },
    changeColor(type, index, color) {
      this.colors[`${type}Colors`][index].value = color
    },
     updateColors(newColors) {
      this.colors = newColors;
    },
  },
}
</script>
